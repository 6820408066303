<template>
  <div class="auth">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <h4 class="card-title bordered">TRADESK</h4>
              <h5 class="mb-4 text-center text-secondary" v-if="! activated && ! invalid">Activating your account</h5>
              <p class="text-center" v-if="! activated && ! invalid">Please Wait...</p>
              <div v-if="activated" class="text-center">
                <div class="alert alert-success text-center">
                  Your account has been activated
                </div>
                <router-link :to="{ name: 'login'}" class="btn btn-theme text-white"><i class="lni-lock mr-2"></i>Go To Login</router-link>
              </div>
              <div v-if="invalid" class="text-center">
                <div class="alert alert-danger text-center">
                  We could not activate your account. Contact us on support@tradesk.co.ke
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      activated: false,
      invalid: false
    }
  },

  methods: {
    fetch() {
      this.activated = false
      this.invalid = false
      let data = { code: this.$route.params.id }

      this.$axios.post(`/api/v1/activate-account`, data).then(() => {
        this.invalid = false
        this.activated = true
      }).catch(() => {
        this.invalid = true
        this.activated = false
      })
    }
  }
}
</script>
